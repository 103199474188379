import React from 'react'
import reactDraft from '../blog/re-writing-drafts-app-in-react/draft-react.png'
import livingRoom from '../blog/creating-a-product-with-react-and-rails/living-room-property-management.png'
import { Link } from 'gatsby'
import Layout from "../../components/Layout";

const PortfolioIndex = () => (
  <Layout>
    <div className="pb-5 mb-5">
      <div className="jumbotron text-center bg-light">
        <div className="container">
          <h1 className="display-4 font-weight-bold">We love <span className="highlight">beautiful</span> work</h1>
          <p className="text-secondary h5 font-italic intake-form-subtext">
            We create code that is well tested, well written, and can scale well.
            Here is a sample of some of the amazing things we've built
          </p>
        </div>
      </div>
      <div className="container mb-5 mt-5">
        <Link to="/re-writing-drafts-app-in-react">
          <div className="row pb-4 align-middle">
            <div className="col-lg-6 align-middle">
              <Link to="/re-writing-drafts-app-in-react">
                <img src={reactDraft} className="card-img-top"/>
              </Link>
            </div>
            <div className="col-lg-6">
              <Link
                to="/re-writing-drafts-app-in-react"
                className='blog-post-title__link'
              >
              </Link>
              <div className="text-bold">
                <Link
                  to="/re-writing-drafts-app-in-react"
                  className='blog-post-title__link'
                >
                  <h2 className="blog-post-title text-bold">
                    Re-Writing DRAFT's Ember application in React
                  </h2>
                </Link>
                <Link
                  to="/re-writing-drafts-app-in-react"
                  className="blog-post-excerpt__link text-secondary"
                >
                  DRAFT needed to re-write their old Ember application in React. Using best practices and tools,
                  I helped re-write DRAFT's web application within 9 months. Here is
                  the overview of how that was accomplished.
                </Link>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <hr/>
      <div className="container mb-5 mt-5">
        <Link to="/creating-a-product-with-react-and-rails">
          <div className="row pb-4 align-middle">
            <div className="col-lg-6">
              <Link
                to="/re-writing-drafts-app-in-react"
                className='blog-post-title__link'
              >
              </Link>
              <div className="text-bold">
                <Link
                  to="/creating-a-product-with-react-and-rails"
                  className='blog-post-title__link'
                >
                  <h2 className="blog-post-title text-bold">
                    Creating a product with React and Rails
                  </h2>
                </Link>
                <Link
                  to="/re-writing-drafts-app-in-react"
                  className="blog-post-excerpt__link text-secondary"
                >
                  By using well known tools and best practices, we created
                  a whole property management product from scratch within months.
                  This is the story about that project.
                </Link>
              </div>
            </div>
            <div className="col-lg-6 align-middle">
              <Link to="/creating-a-product-with-react-and-rails">
                <img src={livingRoom} className="card-img-top"/>
              </Link>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </Layout>
)

export default PortfolioIndex